body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Wigrum", "Segoe UI",
        "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

nav {
    -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.35);
    -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.35);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.35);
    font-family: "Wigrum";
    font-weight: bold;
    color: rgb(255, 255, 255);
    height: 60px;
}

.navbar-brand {
    color: rgb(65, 103, 119) !important;
    width: 128px;
    height: 34px;
    margin-bottom: 19px;
    margin-left: 5px;
}

.page-item.active .page-link {
    background-color: rgb(65, 103, 119) !important;
    border-color: rgb(65, 103, 119) !important;
    color: rgb(255, 255, 255) !important;
}

.list-group-item.active {
    background-color: rgb(65, 103, 119) !important;
    border-color: rgb(65, 103, 119) !important;
}

.page-link {
    color: rgb(65, 103, 119) !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

@font-face {
    font-family: "Wigrum";
    src: url("//d10xx36pi6qs9h.cloudfront.net/font/wigrumweb-regular.eot");
    src: url("//d10xx36pi6qs9h.cloudfront.net/font/wigrumweb-regular.eot?#iefix")
            format("embedded-opentype"),
        url("//d10xx36pi6qs9h.cloudfront.net/font/wigrumweb-regular.woff")
            format("woff");
    font-style: normal;
}

@font-face {
    font-family: "Wigrum";
    src: url("//d10xx36pi6qs9h.cloudfront.net/font/wigrumweb-bold.eot");
    src: url("//d10xx36pi6qs9h.cloudfront.net/font/wigrumweb-bold.eot?#iefix")
            format("embedded-opentype"),
        url("//d10xx36pi6qs9h.cloudfront.net/font/wigrumweb-bold.woff")
            format("woff");
    font-weight: 700;
    font-style: normal;
}
